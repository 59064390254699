@import 'reset.scss';
@import '../../node_modules/bootstrap/scss/bootstrap'; // ? We also need to import bottstrap scss in here
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'); // ? import font

$viewport_tablet: 90rem;
$viewport_phone: 30rem;

:root {
  --main-bg-color: #000;
  --main-bg-color-faded: rgba(0, 0, 0, 0.9);
  --nav-max-width: 37rem;
  --color-gray: #606060;
  --color-light-gray: #c8c8c8;
  --color-white: #fff;
}
// ? Properties

@property --angle {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

// ? import comon styles AFTER all needed assets are in place: resets, icons, fonts, global vars...
@import 'common.scss';

html,
body {
  font-family: 'Inter', Helvetica Neue, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: var(--main-bg-color) url('../img/app_bg_image.svg') no-repeat 0%
    100%;
  background-size: auto 90%;
}

div.toaster-container {
  background: transparent none;
  top: 0;
  left: 0;
  pointer-events: none;
  gap: 2;

  div.toaster-widget {
    position: absolute;
    top: 2rem;
    right: 4rem;
    border: var(--color-gray) 0.063rem solid;
    color: var(--color-white);
    width: 21.9375rem;
    padding: 1rem;
    pointer-events: auto;
    background: var(--main-bg-color) none;

    transform: translateX(30rem);
    transition: transform 0.64s cubic-bezier(1, 0, 0, 1);

    div.toaster-icon {
      transform: rotate(0);
      transition: transform 0.25s cubic-bezier(1, 0, 0, 1);
      cursor: pointer;

      &:hover {
        transform: rotate(-180deg);
      }
    }

    div.toaster-counter {
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 1.25rem;
    }

    &.show {
      transform: translateX(0);
    }
  }
}

/*
? Main sections
*/

body main section {
  width: 100%;
  height: 100%;
}

body main section.login {
  transform: translateY(-100%);
  transition: transform 0.8s cubic-bezier(0.99, -0.67, 0.02, 1.67);
  background: var(--main-bg-color) none;
  color: var(--color-white);
}

body main section.app {
  transform: scale(0.65);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s,
    opacity 0.5s linear 0s;
  // background: red none;
}

body main section.game {
  transform: translateY(100%);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  background: var(--main-bg-color) none;
  color: var(--color-white);
}

body main section.waiting {
  transform: translateY(-100%);
  transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  background: var(--main-bg-color) none;
  color: var(--color-white);

  // ? This div has position-relative in bootstrap mark up

  > div > div div.waiting-content + div {
    min-width: 80%;
  } // ? Guarrada para que todos lo botones se vean igual

  div.waiting-ui,
  div.waiting-play-ui {
    // position: absolute;
    // bottom: 5%;
    // left: 5%;
    width: 12rem;
    margin: 2rem 0 0 0;
    padding: 1rem;
    // height: 100%;
  }
}

/*
? Styles for the app section
*/

// TODO temporary colors for testing

main section.app {
  position: relative;
  max-width: 120rem;
  left: 0; // ? This is needed for margin 0 auto
  right: 0; // ? This is needed for margin 0 auto
  margin: 0 auto;
  border: var(--color-gray) 0.063rem solid;
  // border-left: var(--color-gray) 0.063rem solid;

  div.app-section-header {
    width: 100%;
    height: 11rem;
    // background: purple none;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    div.avatar-container {
      width: 42.7%;
      padding: 0 0 0 10.6770833333%;
      // border-right: var(--color-gray) 0.063rem solid;
      div.avatar-image-container {
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        margin: 4.43rem 0 0 0;
        // background-color: white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        // background-image:url(); This will be the avatar container
      }
    }

    div.title-container {
      width: 57.5%;
      background: #000 none;
      border-bottom: var(--color-gray) 0.063rem solid;
      transform: translateX(-0.063rem);
      h4 {
        padding: 0;
        margin: 0 0 0 13.8712601995%;
        color: var(--color-white);
        font-size: 1.5rem;
        line-height: 11rem;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    div.mobile-icon-container {
      display: none !important;
      color: var(--color-white);

      font-size: 1.5rem;
    }
  }

  div.section-holder {
    height: 100%;

    nav {
      list-style-type: none;
      padding: 14.063rem 0 0 0;
      margin: 0;
      width: 42.5%;
      // background: yellow;
      padding: 0 0 0 10.6770833333%;
      // line-height: 1.5rem;
      border-right: var(--color-gray) 0.063rem solid;

      > li:first-of-type {
        margin-top: 14.063rem;
      }

      > li {
        padding: 0;
        margin: 0 0 -1.5rem 0;

        > span {
          font-size: 3rem;
          letter-spacing: -0.063rem;
          color: var(--color-gray);

          > a {
            color: inherit;
            text-decoration: none;
            transition: color 0.25s linear;

            &:hover {
              color: var(--color-white);
            }
          }
        }

        &.active {
          > span {
            font-weight: 600;
            color: var(--color-white);

            > a {
              color: inherit;
              text-decoration: none;
            }
          }

          ul {
            display: block;
          }
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: calc(0.5rem * 3) 0 calc(0.5rem * 5) 0;
          transform: translateY(-0.25rem);
          display: none;

          li {
            display: inline;

            span {
              color: var(--color-white);
            }

            a {
              text-decoration: none;
              color: var(--color-gray);
              font-weight: 600;
              transition: color 0.5s linear;

              &:hover {
                color: var(--color-white);
                text-decoration: underline;
              }
            }

            &.current {
              a {
                color: var(--color-white);
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    div.holder-frame {
      width: 57.5%;
      height: 100%;
      // background: blue none;
      overflow: hidden;
      padding: 11rem 0 0 0;
      // border-left: var(--color-gray) 0.063rem solid;
      // transform: translateX(-0.036rem);

      position: relative;

      div.holder {
        width: 100%;
        height: 100%;

        transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);

        // TODO temporary classes for markup

        // transform: translateY(-100%); // ? Vertical movement

        div.block-content {
          // width: 100%;
          // height: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);

          // TODO temporary classes for markup

          // &:first-of-type {
          //   transform: translateX(-200%);
          // }

          // &:nth-child(2) {
          //   transform: translateX(-200%);
          // }

          // * Styles for block content

          > div {
            color: var(--color-white);
            background: var(--main-bg-color) none;
          } // ? common styles

          div[class*='slide'] {
            opacity: 0;
            transition: opacity 0.5s linear 0s;

            &.viewing {
              opacity: 1;
              transition-delay: 0.5s;
            }
          }

          // * Slide-Overview

          div.slide-overview {
            overflow-y: auto;
            overflow-x: hidden; // TODO this could change
            div.overview-container {
              // width: 100%;
              // height: 100%;

              // margin: 0 auto;
              // background: red none;

              div.overview-graph-container {
                // width: 100%;
                max-width: 52rem;
                // width: 100%;
                // height: 30rem;
                // background: cyan none;
                position: relative;

                div.overview-total-header {
                  font-size: 1.15rem;
                  color: var(--color-white);
                  text-transform: uppercase;
                  text-align: center;

                  span {
                    font-weight: 700;
                  }
                }

                div.overview-graph-wheel {
                  // margin: 1.5rem 0;

                  margin: 1.5rem auto 1.5rem auto;

                  div.overview-wheel-container {
                    margin: 1.5rem 0;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    // transform: scale(1.35);

                    svg {
                      transform: rotate(-90deg) scale(1.15);
                      margin: auto;
                      circle {
                        transition: stroke-dashoffset 0.75s
                          cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
                        stroke-width: 1.5em;
                        stroke: var(--color-gray);
                      }
                      .bar-bg-games,
                      .bar-bg-points,
                      .bar-bg-tournaments-played,
                      .bar-bg-tournaments-rounds {
                        stroke-dashoffset: 0;
                      }
                      .bar-games,
                      .bar-points,
                      .bar-tournaments-played,
                      .bar-tournaments-rounds {
                        stroke: var(--color-white);
                      }
                    }

                    &:before {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      background: var(--main-bg-color);
                      width: 15rem;
                      height: 15rem;
                      display: block;
                      content: '';
                      border-radius: 50%;
                    }

                    &:after {
                      content: attr(data-percent);
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                      background: transparent none;
                      // width: 15rem;
                      // height: 15rem;
                      color: var(--main-bg-color1);
                      font-size: 2rem;
                      font-weight: 700;
                      display: block;
                      position: absolute;
                      // transform: rotate(-90deg);
                    }
                  }
                }
              }

              div.overview-legend {
                max-width: 15rem;
                width: 100%;
                margin: 0 auto;

                > div {
                  div.overview-legend-color {
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                  }

                  div.overview-legend-text {
                    font-weight: 700;
                    margin: 0 0 0 1rem;
                  }
                }

                div:first-of-type {
                  div.overview-legend-color {
                    background-color: var(--color-white);
                  }
                }

                div:last-of-type {
                  div.overview-legend-color {
                    background-color: var(--color-gray);
                  }
                }
              }
            }
          }

          // * History Slide

          div.slide-history {
            // width: 100%;
            overflow-y: auto;
            overflow-x: hidden;

            div.history-row {
              border-bottom: var(--color-gray) 0.063rem solid;
              padding: 0 12.6926563917% 0 13.8712601995%;

              div.history-avatar {
                width: 3rem;
                height: 3rem;
                border-radius: 3rem;
                // background-color: white;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                // background-image:url(); This will be the avatar container
              }

              div.user-data,
              div.player-data {
                span.history-score {
                  font-size: 3.75rem;
                  font-weight: 600;
                  &.lose {
                    color: var(--color-gray);
                  }
                }
              } // ? Styles for left and right side of row

              div.user-data {
                span.history-name {
                  padding: 0 0 0 2rem;
                }

                span.history-score {
                  margin: 0 0 0 6rem;
                }
              }

              div.timestamp {
                padding: 4.97rem 1rem;
                color: var(--color-gray);
                // border-left: var(--color-gray) 0.063rem solid;
                // border-right: var(--color-gray) 0.063rem solid;
                text-align: center;

                span:first-of-type {
                  font-weight: 600;
                  text-align: center;
                  // color: var(--color-white);
                }
              } // ? Styles for middle div contining date

              div.player-data {
                span.history-name {
                  padding: 0 2rem 0 0;
                }

                span.history-score {
                  margin: 0 6rem 0 0;
                }
              }
            }
          } // ? History Slide

          // * Edit your info Slide

          div.slide-edit {
            overflow-y: auto;
            div.edit-form-container {
              max-width: 46.563rem;
              width: 100%;
              margin: 10.5rem auto 0 auto;
              // // ! clear this margin

              // margin: 0 0 0 9.563rem;

              div.edit-avatar {
                width: 100%;
                // background: red none;

                div.avatar-image {
                  width: 5.563rem;
                  height: 5.563rem;
                  border-radius: 5.563rem;
                  background-color: transparent;
                  border: var(--color-gray) 0.063rem solid;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center center;
                }

                input[type='file'] {
                  display: none;
                }

                label.avatar-file-upload {
                  font-weight: 500;
                  color: var(--main-bg-color);
                  font-size: 1.5rem;
                  letter-spacing: -0.063rem;
                  margin: 0 0 0 4rem;
                  cursor: pointer;
                  opacity: 1;
                  transition: opacity 0.25s linear;

                  &:hover {
                    opacity: 0.5;
                  }
                }

                span {
                  color: var(--main-bg-color);
                  margin: 0 0 0 2.3rem;
                }
              }

              div.edit-fields {
                margin: 6.25rem 0 0 0;

                input {
                  width: 100%;
                  border: none;
                  border-bottom: var(--color-light-gray) 0.063rem solid;
                  padding: 0 0 0.62rem 0;
                }

                input:focus {
                  outline: none;
                }

                input:not([type='text']),
                input:not([type='password'] + div.show) {
                  margin: 1.313rem 0 0 0;
                }

                input::placeholder {
                  font-style: italic;
                  color: var(--color-gray);
                }
              }

              div.edit-info-button-container {
                max-width: 27.438rem;
                width: 100%;
                margin: 6rem auto 0 auto;
              }
            }
          } // ? Edit your info slide
        }

        // * Slide training

        div.slide-training {
          overflow-y: auto;

          div.training-form-container {
            max-width: 46.563rem;
            width: 100%; // ! bootstrap w-100 does not apply here...
            margin: 10.5rem auto 0 auto;

            div.training-fields {
              input {
                width: 100%;
                border: none;
                border-bottom: var(--color-light-gray) 0.063rem solid;
                padding: 0 0 0.67rem 0;
              }

              input:focus {
                outline: none;
              }

              input::placeholder {
                font-style: italic;
                color: var(--color-gray);
              }

              > div {
                > input {
                  margin: 8.75rem 0 0 0;
                  flex-basis: 80%;
                }

                > div {
                  flex-basis: 20%;
                  input[type='checkbox'] {
                    padding: 0;
                    width: 1.5rem;
                    height: 1.5rem;
                    // flex-basis: 30%;

                    &:checked {
                      background: var(--main-bg-color);
                      accent-color: var(--main-bg-color);

                      + label {
                        text-decoration: underline;
                      }
                    }
                  }
                  label {
                    color: var(--main-bg-color);
                    // flex-basis: 70%;
                    text-align: right;
                    font-size: 0.813rem;
                    font-weight: 700;
                    padding: 0 0 0 1rem;
                  }
                }
              }
            }

            div.training-players-range-container {
              margin: 7.5rem 0 0 0;

              h4 {
                color: var(--main-bg-color);
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                font-weight: 600;
                text-align: center;
              }

              h4 + input[type='range'] {
                margin: 2.688rem 0 0 0;
              }
            }

            div.training-button-container {
              max-width: 27.438rem;
              width: 100%;
              margin: 6rem auto 0 auto;
              position: relative;

              &::after {
                content: 'Training mode matches are not counted in your stats.';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                padding: 4rem 0 0 0;
                margin: 0 auto;
                color: var(--main-bg-color);
                font-size: 0.813rem;
                font-weight: 700;
                text-align: center;
                pointer-events: none;
              }
            }
          }
        } // ? Slide training

        // * Slide public game

        div.slide-public {
          // > div {
          //   cursor: pointer;

          //   &:hover h4 {
          //     opacity: 0.5;
          //   }
          // }

          // h4 {
          //   color: var(--color-white);
          //   font-size: 2.5rem;
          //   font-weight: 600;
          //   opacity: 1;
          //   text-align: center;
          //   transition: opacity 0.25s linear;
          // }

          > div i {
            font-size: 20rem;
            color: var(--main-bg-color);
          }

          > div div.public-button-container {
            max-width: 27.438rem;
            width: 100%;
            position: relative;

            &::after {
              content: 'Points will be randomly generated. Good luck!';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              padding: 4rem 0 0 0;
              margin: 0 auto;
              color: var(--main-bg-color);
              font-size: 0.813rem;
              font-weight: 700;
              text-align: center;
              pointer-events: none;
            }
          }
        } // ? Slide Public game

        // TODO this up section stills needs to be decided

        // * Slide private game

        div.slide-private {
          > div {
            transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),
              height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),
              background-color 0.5s linear;
            position: relative;

            div.private-goback-indicator {
              position: absolute;
              font-weight: 600;
              // background: var(--main-bg-color) none;
              // color: var(--color-white);
              padding: 0.85rem 1.5rem;
              // border-radius: 0.5rem;
              cursor: pointer;
              top: 2rem;
              opacity: 0;
              visibility: hidden;

              span {
                font-size: 1rem;
                display: block;

                text-transform: capitalize;
              }

              i {
                font-size: 1.5rem;
              }
            }

            h4 {
              cursor: pointer;
            }

            &:hover h4 {
              opacity: 0.5;
            }

            div.private-game-button-container {
              width: 100%;
              margin: 5rem auto 0 auto;
            }

            // ? CREATE DIV

            &:first-of-type {
              border-right: var(--color-gray) 0.063rem solid;

              div.private-goback-indicator {
                right: 3rem;
                pointer-events: none;

                span {
                  margin: 0 1rem 0 0;
                  pointer-events: auto;
                }
              }

              div.private-create-form-container {
                max-width: 27.438rem;
                width: 100%;
                margin: 3rem auto 0 auto;
                display: none;
                background: transparent;

                div.private-create-fields {
                  h4 {
                    color: var(--main-bg-color) !important;
                    text-transform: uppercase;
                    margin: 0;
                    padding: 0;
                    font-weight: 600;
                    text-align: center;
                    font-size: 1.5rem;
                    margin: 1.5rem 0;
                  }

                  h4 + input[type='range'] {
                    margin: 2.688rem 0 0 0;
                  }
                }

                // div.private-game-button-container {
                //   width: 100%;
                //   margin: 5rem auto 0 auto;
                // }
              }
            }

            &:last-of-type {
              div.private-goback-indicator {
                left: 3rem;
                pointer-events: none;

                span {
                  margin: 0 0 0 1rem;
                  pointer-events: auto;
                }
              }

              div.private-join-form-container {
                max-width: 27.438rem;
                width: 100%;
                margin: 3rem auto 0 auto;
                // opacity: 0;
                display: none;
                background: transparent;
                // transition: opacity 0.5s, display 0.5s;
                // transition-delay: 0.25s, 0s;

                div.private-join-fields {
                  input {
                    width: 100%;
                    border: none;
                    border-bottom: var(--color-light-gray) 0.063rem solid;
                    margin: 1.85rem 0 0 0;
                    padding: 0 0 0.78rem 0;
                    font-size: 1.5rem;
                    font-weight: 600;
                    background: transparent;
                  }

                  input:focus {
                    outline: none;
                  }

                  input::placeholder {
                    font-style: italic;
                    color: var(--color-gray);
                  }
                }
              }
            }

            h4 {
              color: var(--color-white);
              font-size: 3rem;
              font-weight: 600;
              opacity: 1;
              text-align: center;
              transition: opacity 0.25s linear;
            }
          }

          &.showing-create-form {
            > div:first-of-type {
              // overflow-y: auto;

              div.private-goback-indicator {
                opacity: 1;
                visibility: visible;
              }

              border-right: none;

              width: 100% !important;
              height: 100% !important;
              background: var(--color-white) !important;
              color: var(--main-bg-color) !important;

              div.private-create-form-container {
                display: block;
                background: transparent !important;

                div.private-create-fields {
                  input {
                    background: transparent !important;
                  }
                }
              }

              h4 {
                color: var(--main-bg-color) !important;
              }
            }
            > div:last-of-type {
              width: 0 !important;

              h4 {
                color: transparent !important;
              }
            }
          }

          &.showing-join-form {
            div.private-goback-indicator {
              opacity: 1;
              visibility: visible;
            }

            > div:first-of-type {
              width: 0 !important;

              h4 {
                color: transparent !important;
              }
            }
            > div:last-of-type {
              // overflow-y: auto;

              width: 100% !important;
              height: 100% !important;
              background: var(--color-white) !important;
              color: var(--main-bg-color) !important;

              div.private-join-form-container {
                display: block;
                background: transparent !important;

                div.private-join-fields {
                  input {
                    background: transparent !important;
                  }
                  // opacity: 1;
                }
              }

              h4 {
                color: var(--main-bg-color) !important;
              }
            }
          }
        } // ? Slide Private game

        // TODO this up section stills needs to be decided

        // * Slide create tournament

        div.slide-create {
          overflow-y: auto;

          div.create-tournament-form-container {
            max-width: 46.563rem;
            width: 100%;
            margin: 10.5rem auto 0 auto;

            // ! clear this margin

            // margin: -19.938rem 0 0 0;

            div.create-tournament-fields {
              input {
                width: 100%;
                border: none;
                border-bottom: var(--color-light-gray) 0.063rem solid;
                padding: 0 0 0.67rem 0;
              }

              input:focus {
                outline: none;
              }

              input::placeholder {
                font-style: italic;
                color: var(--color-gray);
              }
            }

            div.create-tournament-game-points-range-container,
            div.create-tournament-player-number-range-container {
              margin: 7.5rem 0 0 0;

              h4 {
                color: var(--main-bg-color);
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                font-weight: 600;
                text-align: center;
              }

              h4 + input[type='range'] {
                margin: 2.688rem 0 0 0;
              }
            }

            div.create-tournament-button-container {
              max-width: 27.438rem;
              width: 100%;
              margin: 6rem auto 0 auto;
            }
          }
        } // ? Slide create tournamet

        // * Slide join tournament

        div.slide-join {
          overflow-y: auto;

          div.join-list-container {
            width: 100%;
            // pointer-events: none;

            div.join-list-row-container {
              width: 100%;
              border-bottom: var(--color-gray) 0.063rem solid;

              div.join-list-row {
                width: 67.54306436998%;
                transform: translateX(-1.563rem);
                margin: 0 auto;
                padding: 4.125rem 0;

                div.left-side {
                  div.join-creator-avatar {
                    width: 3.063rem;
                    height: 3.063rem;
                    border-radius: 3.063rem;
                    background-color: var(--color-white);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    // background-image: url(); // ? here we set the avatar url
                  }

                  div.tournament-data {
                    margin: 0 0 0 2.25rem;

                    div:first-of-type {
                      margin: 0.188rem 0 0 0;
                      span {
                        color: var(--color-gray);
                      }
                    }

                    div:last-of-type {
                      color: var(--color-gray);
                      // transform: translateY(-0.125rem);
                      span {
                        color: var(--color-white);
                        font-weight: 700;
                      }
                    }
                  }
                }

                div.right-side {
                  text-transform: uppercase;
                  font-size: 0.625rem;
                  font-weight: 700;
                  opacity: 1;
                  transition: opacity 0.25s linear;
                  span {
                    cursor: pointer;
                    opacity: 1;
                    visibility: visible;

                    &.hidden {
                      opacity: 0;
                      visibility: hidden;
                    }
                  }

                  &:hover {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
        } // ? Join game

        // TODO this up section stills needs to be decided

        // * Slide hall of fame (Blockchain)

        div.slide-hall {
          overflow-y: auto;
          div.hall-metamask {
            width: 100%;
            height: 6rem;

            div.metamask-ui {
              width: calc(100% - 4.8913043478% - 13.8713%);
              height: 100%;
              margin: 0 0 0 calc(13.8713% - 4.8913043478%);
              // background: green none;

              div.metamask-address {
                font-weight: 700;
                color: var(--main-bg-color);
              }

              button.metamask-button {
                width: 9.625rem;
                height: 2.438rem;
                background: var(--main-bg-color);
                border-radius: 1.536rem;
                color: var(--color-white);
                border: none;
                padding: 0.5rem 0;
                border: transparent 0.063rem solid;
                font-size: 0.625rem;
                font-weight: 700;
                text-transform: uppercase;
                transition: color 0.25s linear;

                &:hover {
                  color: var(--color-light-gray);
                }
              }
            }
          }

          div.hall-list-container {
            width: 100%;
            div.hall-list-row-container {
              width: 100%;
              border-bottom: var(--color-gray) 0.063rem solid;
              div.hall-list-row {
                width: 67.54306436998%;
                transform: translateX(-1.563rem);
                margin: 0 auto;
                padding: 4.125rem 0;

                div.left-side {
                  div.tournament-winner-avatar {
                    width: 3.063rem;
                    height: 3.063rem;
                    border-radius: 3.063rem;
                    background-color: var(--color-white);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    // background-image: url(); // ? here we set the avatar url
                  }

                  div.tournament-data {
                    margin: 0 0 0 2.25rem;

                    div:first-of-type {
                      margin: 0.188rem 0 0 0;
                      span {
                        color: var(--color-gray);
                      }
                    }

                    div:last-of-type {
                      color: var(--color-gray);
                      // transform: translateY(-0.125rem);
                      span {
                        color: var(--color-white);
                        font-weight: 700;
                      }
                    }
                  }
                }

                div.right-side {
                  text-transform: uppercase;
                  font-size: 0.625rem;
                  font-weight: 700;
                  span {
                    cursor: pointer;
                    opacity: 1;
                    visibility: visible;
                    transition: opacity 0.25s linear;

                    &:hover {
                      opacity: 0.5;
                    }

                    &.hidden {
                      opacity: 0;
                      visibility: hidden;
                    }
                  }
                }
              }
            }
          }
        } // ? Slide Hall of Fame (blockchain)

        // * Slide Friends list

        div.slide-list {
          overflow-y: auto;

          div.friends-list-container {
            width: 100%;
            pointer-events: none;
            div.friends-list-row-container {
              width: 100%;
              border-bottom: var(--color-gray) 0.063rem solid;

              div.friends-list-row {
                width: 67.54306436998%;
                transform: translateX(-1.563rem);
                margin: 0 auto;
                padding: 4.188rem 0;
                div.friend-username {
                  position: relative;

                  color: var(--color-gray);

                  &.online {
                    color: var(--color-white);
                  }

                  span:first-of-type {
                    font-weight: 700;
                  }

                  &::before {
                    content: '\2715';
                    position: absolute;
                    left: -2.35rem;
                    display: block;
                    color: var(--color-white);
                    transform: rotate(180deg) scale(1.5);
                    cursor: pointer;
                    pointer-events: auto;
                    opacity: 0.5;
                    transition: opacity 0.25s linear,
                      transform 0.25s cubic-bezier(0.23, 1, 0.32, 1);
                  }

                  &:hover {
                    &::before {
                      opacity: 1;
                      transform: rotate(0) scale(1.9);
                    }
                  }
                }

                div[class*='friend-list-user-data'] {
                  text-transform: uppercase;
                  font-size: 1rem;
                  font-weight: 700;

                  span {
                    color: var(--color-light-gray);
                  }
                }

                div.friend-avatar {
                  width: 3.063rem;
                  height: 3.063rem;
                  border-radius: 3.063rem;
                  // background-color: white;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center center;
                  margin: 0 1.5rem;
                  // background-color: var(--color-white);
                  // background-repeat: no-repeat;
                  // background-position: center center;
                  // background-image: url(); // ? here we set up the image
                }
              }
            }
          }
        } // ? Slide friends list

        // * Slide Add friends

        div.slide-add {
          overflow-y: auto;

          div.friends-search {
            width: 100%;
            height: 12.478rem;

            div.friends-search-ui {
              position: relative;
              width: 67.54306436998%;
              margin: 0 auto;
              transform: translateX(-1.563rem);

              div.search-icon {
                position: absolute;
                left: 0;
                display: block;
                color: var(--color-gray);
                font-weight: 700;
              }

              input[type='search'] {
                width: 100%;
                width: 100%;
                border: none;
                border-bottom: var(--color-light-gray) 0.063rem solid;
                padding: 0 0 0.938rem 2rem;
              }

              input[type='search']:focus {
                outline: none;
              }

              input[type='search']::placeholder {
                font-style: italic;
                color: var(--color-gray);
              }
            }
          }

          div.search-list-container {
            width: 100%;
            div.search-list-row-container {
              width: 100%;
              border-bottom: var(--color-gray) 0.063rem solid;
              div.search-list-row {
                width: 67.54306436998%;
                transform: translateX(-1.563rem);
                margin: 0 auto;
                padding: 4.188rem 0;

                div.search-username {
                  color: var(--color-white);
                  font-weight: 700;
                  cursor: pointer;
                  opacity: 1;
                  transition: opacity 0.25s linear;

                  &:hover {
                    opacity: 0.5;
                  }
                }

                div[class*='search-list-user-data'] {
                  text-transform: uppercase;
                  font-size: 1rem;
                  font-weight: 700;

                  span {
                    color: var(--color-light-gray);
                  }
                }

                div.search-avatar {
                  width: 3.063rem;
                  height: 3.063rem;
                  border-radius: 3.063rem;
                  background-color: white;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position: center center;
                  margin: 0 1.5rem;
                  // background-color: var(--color-white);
                  // background-repeat: no-repeat;
                  // background-position: center center;
                  // background-image: url(); // ? here we set up the image
                }
              }
            }
          }
        } // ? Slide add friends

        // * Slide about the game

        div.slide-about-game {
          overflow-y: auto;
          div.about-text-container {
            margin: 3.969rem 0 0 0;
            max-width: calc(47.188rem + 13.8713%);
            padding: 0 0 0 13.8713%;
            width: 100%;
            p {
              color: var(--color-white);
              font-size: 1.65rem;
              text-align: justify;
              line-height: 2.475rem;
              text-indent: 3.5rem;
              font-weight: 700;
              padding: 0;
              margin: 0;
            }
          }
        } // ? Slide about game

        // * About team slide

        div.slide-about-team {
          div.about-team-content {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0;
            grid-row-gap: 0;

            > div {
              a {
                display: block;
                width: 100%;
                height: 100%;
                text-decoration: none;

                div.student-info {
                  span {
                    display: block;
                    color: var(--color-white);

                    &.student-name {
                      font-size: 3rem;
                      font-weight: 700;

                      transform: translateY(1rem);
                      transition: transform 0.45s
                        cubic-bezier(0.075, 0.82, 0.165, 1);
                    }

                    &.student-role {
                      color: var(--color-gray);
                      opacity: 0;
                      transform: translateY(1rem);
                      transition: opacity 0.25s linear,
                        transform 0.45s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                  }
                }

                &:hover {
                  div.student-info {
                    span {
                      &.student-name {
                        transform: translateY(0);
                      }
                      &.student-role {
                        transform: translateY(0);
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }

            > div:first-of-type {
              border-right: var(--color-gray) 0.063rem solid;
              // border-bottom: var(--color-gray) 0.031rem solid;
            }

            > div:nth-child(3) {
              border-top: var(--color-gray) 0.063rem solid;
              border-right: var(--color-gray) 0.063rem solid;
            }

            > div:last-child {
              border-top: var(--color-gray) 0.063rem solid;
            }
          }
        } // ? Slide about the team

        // * Log Out slide

        div.slide-confirm {
          > div i {
            font-size: 20rem;
            color: var(--main-bg-color);
          }

          > div div.logout-button-container {
            max-width: 27.438rem;
            width: 100%;
          }
        } // ? Slide log out
      }
    }
  }
}

/*
? Styles for the login section section
*/

main section.login {
  div.login-panels {
    max-width: 120rem;
    margin: 0 auto;
    overflow: hidden;
    // background: cyan none;

    div.login-splash {
      position: absolute;
      top: 0;
      left: 0;

      transform: scale(1);
      transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

      // background: red none;

      div {
        // text-align: center;
        position: absolute;
        max-width: 75.25rem;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;

        &.ball-container {
          opacity: 1;
          transition: opacity 0.5s linear;
          transform: scale(0);
          transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

          cursor: pointer;

          &.full {
            transform: scale(1);
          }

          &.fullest {
            transform: scale(1.5);
          }

          div.ball {
            // width: 53.073089701%;
            // height: 66.080661841%;
            width: 39.9375rem;
            height: 39.9375rem;
            background: var(--color-white);
            // border-radius: 50%;
            border-radius: 39.9375rem;
            display: block;

            transform: translateX(0);
          } // ? try to make this reposnsive
        }

        &.shovels-container {
          div.shovels {
            width: 0%; // ? this will be set to 100
            height: 0%; // ? this will be set to 28.955532575%
            border-left: var(--color-white) 1.5rem solid;
            border-right: var(--color-white) 1.5rem solid;

            transition: width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s,
              height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

            &.showing-shovels {
              width: 100%;
              height: 28.955532575%;
              transition-delay: 1s, 0.5s;
            }

            &.reducing-shovels {
              width: 80%;
              height: 28.955532575%;
              transition-delay: 1.2s, 0s;
            }

            pointer-events: none;
          }
        }

        &.letters-container {
          div.letters {
            width: 82.724252492%;
            height: 10rem;
            pointer-events: none;

            color: var(--color-gray);

            > ul {
              list-style: none;
              padding: 0;
              margin: 0;
              width: 100%;

              li {
                text-transform: uppercase;
                text-align: center;
                display: inline-block;
                overflow: hidden;

                &:first-of-type span {
                  transition-delay: 0.75s;
                }

                &:nth-child(2) span {
                  transition-delay: 0.65s;
                }

                &:nth-child(3) span {
                  transition-delay: 0.45s;
                }

                &:last-of-type span {
                  transition-delay: 0.25s;
                }

                span {
                  font-weight: 600;
                  font-size: 12.5rem;
                  line-height: 9.5rem;
                  display: block;
                  transform: translateY(100%);
                  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                &.revealing-letter {
                  &:first-of-type span {
                    transition-delay: 0.25s;
                  }

                  &:nth-child(2) span {
                    transition-delay: 0.45s;
                  }

                  &:nth-child(3) span {
                    transition-delay: 0.65s;
                  }

                  &:last-of-type span {
                    transition-delay: 0.75s;
                  }

                  span {
                    transform: translateY(0);
                  }
                }
              }
            }
          }
        }

        &.menu-intro-container {
          div.menu-intro {
            // background-color: yellow;
            max-width: 34.5rem;
            width: 100%;
            margin: 0 auto;
            height: 10%;

            overflow: hidden;

            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              height: 100%;
              width: 100%;

              transform: translateY(100%);
              transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

              &.showing-menu-intro {
                transform: translateY(0);
              }

              li {
                display: inline;
                font-size: 1.5rem;
                color: var(--color-gray);
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.125rem;

                a {
                  color: inherit;
                  text-decoration: none;

                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }

    div.login-signin,
    div.login-signup {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.8);

      opacity: 0;

      transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1),
        opacity 0.25s linear;

      div.login-form {
        max-width: 39.5rem;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        h2 {
          height: 20.579110651%;
          width: 100%;
          margin: 0;
          padding: 0;
          font-weight: 600;
          font-size: 4rem;
          letter-spacing: -0.125rem;
          font-family: 'Inter', sans-serif;
        }

        div.login-form-container {
          width: 100%;
          height: 79.420889349%;
          background: var(--color-white);

          padding: 7.75rem 0 4rem 0;

          div.fields {
            margin: 0.125rem auto 0 auto;
            max-width: 25.875rem;
            width: 100%;

            input {
              width: 100%;
              border: none;
              border-bottom: var(--color-light-gray) 0.063rem solid;
              padding: 0 0 0.67rem 0;
            }

            input:focus {
              outline: none;
              background: inherit;
            }

            input::placeholder {
              font-style: italic;
              color: var(--color-gray);
            }

            div.password-field-container {
              input {
                margin: 2.75rem 0 0 0;
              }

              div.password-input-toggler {
                padding-top: 1.5rem !important;
              }
            }
          }

          div.footer {
            max-width: 25.875rem;
            width: 100%;
            margin: 0 auto;

            span {
              color: var(--main-bg-color);
              text-transform: uppercase;
              margin: 1rem 0 0 0;
              text-align: center;
              display: block;
              font-weight: 600;

              a {
                color: inherit;
              }
            }
          }
        }
      }
    }

    div.login-signin {
      transform: translateX(-100%);
    }

    div.login-signup {
      transform: translateX(100%);

      div.login-form-container {
        padding: 5.5rem 0 4rem 0 !important;

        input {
          margin: 2.25rem 0 0 0 !important;
        }
      }
    }
  }
}

main section.game {
  div.slide-gaming {
    // position: relative;
    max-width: 120rem;
    // left: 0;
    // right: 0;
    margin: 0 auto;

    div[class*='game-ui-buttons'] {
      position: absolute;
      height: 100%;
      width: 18.64585%;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      &.game-ui-buttons-left {
        left: 0;
      }
      &.game-ui-buttons-right {
        right: 0;
      }

      button {
        all: unset;
        font-size: 2rem;
        padding: 2rem;
        cursor: pointer;

        i {
          pointer-events: none;
        }
      }

      &.showing-on-mobile {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }

    canvas {
      border: var(--color-white) 0.063rem solid;
      width: 62.7083333333%;
      height: auto;
      aspect-ratio: 16/9;
    }
  }
}

main section.waiting {
  div.slide-waiting {
    > div {
      // border: 0.063rem var(--color-white) solid;
      width: 50%;
      height: 50%;
      // padding: 5rem;
      padding:0;
    }

    h4 {
      // font-size: 3.85rem;
      // line-height: 3.85rem;
      font-size: 1.85rem;
      line-height: 1.85rem;
      letter-spacing: -0.063rem;
      font-weight: 700;
      margin: 0;
      color: var(--color-white);

      text-align: center;

      span {
        color: var(--color-light-gray);
        font-weight: 700;
      }
    }
    h6 {
      font-size: 1.5rem;
      margin: 1rem 0 0 0;
      text-align: center;
      font-weight: 700;
      letter-spacing: -0.063rem;
      color: var(--color-gray);
    }
  }
}

/*
* Animation based classes
*/

body.not-logged main section.login {
  transform: translateY(0);

  &.showing-signin div.login-splash,
  &.showing-signup div.login-splash {
    transform: scale(0.75);
  }

  &.showing-signin div.login-signin {
    transform: translateX(0);
    opacity: 1;
  }

  &.showing-signup div.login-signup {
    transform: translateX(0);
    opacity: 1;
  }
}

body.logged:not(.playing):not(.waiting) main section.app {
  transform: scale(1.002);
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.65s, 0s;
}

body.playing:not(.not-logged).logged main section.game {
  transform: translateY(0);
  transition-delay: 0.25s;
}

body.waiting:not(.not-logged).logged main section.waiting {
  transform: translateY(0);
  transition-delay: 0.25s;
}

@import 'viewports/tablet.scss';
@import 'viewports/phone.scss';
