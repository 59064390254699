@media screen and (max-width: $viewport-tablet) {
  body {
    background-image: none;
  }

  main section.login {
    div.login-panels {
      max-width: 90rem;

      div.login-splash {
        div {
          max-width: none;
          width: 85%;

          &.ball-container {
            div.ball {
              width: 30.9375rem;
              height: 30.9375rem;
              border-radius: 30.9375rem;
            }
          }

          &.menu-intro-container {
            div.menu-intro {
              ul {
                li {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }
    }
  }

  main section.app {
    max-width: none;
    border-left: none;
    border-right: none;
    div.app-section-header {
      flex-direction: row-reverse !important;
      border-bottom: var(--color-gray) 0.063rem solid;
      height: 6rem;
      background: var(--main-bg-color) none;

      div.avatar-container {
        width: 15%;
        padding: 0;
        justify-content: center !important;

        div.avatar-image-container {
          margin: 0;
        }
      }

      div.title-container {
        width: 70%;
        border-bottom: none;
        background: transparent none;

        h4 {
          margin: 0;
          text-align: center;
          font-size: 1rem;
          line-height: 6rem;
        }
      }

      div.mobile-icon-container {
        width: 15%;
        display: flex !important;

        i {
          cursor: pointer;
        }
      }
    }

    div.section-holder {
      width: 100%;
      // display: block !important;

      nav {
        width: 100%;
        height: calc(
          100% - 6rem
        ); // TODO change 11 rem to final height of div.app-section-header
        position: absolute;
        top: 6rem; // TODO change 11 rem to final height of div.app-section-header
        left: 0;
        padding: 0 0 2.5rem 6.771%;
        border-right: none;
        transform: translatex(-100%);
        transition: transform 0.65s cubic-bezier(1, -0.01, 0.81, 1);
        background: var(--main-bg-color);
        z-index: 10;
        overflow-y: auto;

        &.showing-mobile {
          transform: translateX(0);
          transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        > li:first-of-type {
          margin-top: 5.063rem;
        }

        > li {
          margin: 0 0 0 0;
          > span {
            font-size: 1.5rem;
            letter-spacing: 0;
          }

          ul {
            margin: calc(0.5rem * 3) 0 calc(0.5rem * 3) 0;
          }
        }
      }

      div.holder-frame {
        width: 100%;
        padding: 6rem 0 0;

        div.holder {
          div.block-content {
            // * History Slide

            div.slide-history {
              div.history-row {
                padding: 0 5%;

                div.user-data,
                div.player-data {
                  span.history-score {
                    font-size: 1.875rem;
                    font-weight: 600;
                    &.lose {
                      color: var(--color-gray);
                    }
                  }
                } // ? Styles for left and right side of row

                div.user-data {
                  span.history-name {
                    padding: 0 0 0 2rem;
                  }

                  span.history-score {
                    margin: 0 0 0 2rem;
                  }
                }

                div.timestamp {
                  padding: 2.98rem 1rem;
                }

                div.player-data {
                  span.history-name {
                    padding: 0 2rem 0 0;
                  }

                  span.history-score {
                    margin: 0 2rem 0 0;
                  }
                }
              }
            } // ? History Slide

            // * Edit Slide

            div.slide-edit {
              div.edit-form-container {
                max-width: none;
                width: 85%;
                margin: 4rem auto 4rem auto;

                div.edit-info-button-container {
                  max-width: none;
                }
              }
            } // ? Edit Slide

            // * Training Slide

            div.slide-training {
              div.training-form-container {
                max-width: none;
                width: 85%;
                margin: 4rem auto 4rem auto;

                div.training-fields {
                  input:nth-child(2) {
                    margin: 4.375rem 0 0 0;
                  }
                }

                div.training-players-range-container {
                  margin: 3.75rem 0 0 0;
                }

                div.training-button-container {
                  max-width: none;
                }
              }
            } // ? Training Slide

            div.slide-public {
              > div i {
                display: none;
              }

              > div div.public-button-container {
                width: 125%;
              }
            }

            div.slide-private {
              h4 {
                font-size: 1.5rem;
              }

              div.private-create-form-container,
              div.private-join-form-container {
                width: 85% !important;
                max-width: none !important;
              }
            }

            // * Slide create tournament
            div.slide-create {
              div.create-tournament-form-container {
                max-width: none;
                width: 85%;
                margin: 4rem auto 4rem auto;
                div.create-tournament-game-points-range-container {
                  margin: 3.5rem 0 0 0;
                }

                div.create-tournament-button-container {
                  max-width: none;
                }
              }
            } // ? Slide create tournamet

            div.slide-join {
            } // ? Join game

            // TODO this up section stills needs to be decided

            // * Slide hall of fame (Blockchain)

            div.slide-hall {
              div.hall-metamask {
                div.metamask-ui {
                  width: 85%;
                  margin: 0 auto;
                }
              }

              div.hall-list-container {
                div.hall-list-row-container {
                  div.hall-list-row {
                    width: 85%;
                    transform: none;
                    padding: 3.125rem 0;
                  }
                }
              }
            } // ? Slide create tournamet

            // * Slide Friends list

            div.slide-list {
              div.friends-list-container {
                div.friends-list-row {
                  width: 85%;
                  transform: none;
                  padding: 2.188rem 0;
                }
              }
            } // ? Slide friends list

            // * Slide Add friends

            div.slide-add {
              div.friends-search {
                height: 6.528rem;
                div.friends-search-ui {
                  width: 85%;
                  transform: none;
                }
              }

              div.search-list-container {
                div.search-list-row {
                  width: 85%;
                  transform: none;
                  padding: 3.188rem 0;
                }
              }
            } // ? Slide add friends

            // * Slide about the game

            div.slide-about-game {
              div.about-text-container {
                max-width: none;
                width: 85%;
                // margin: 3.969rem auto 0 auto;
              }
            } // ? Slide about game

            // * About team slide

            div.slide-about-team {
              div.about-team-content {
                > div {
                  a {
                    div.student-info {
                      span {
                        &.student-name {
                          transform: translateY(0);
                        }
                        &.student-role {
                          transform: translateY(0);
                          opacity: 1;
                        }
                      }
                    }
                  }
                }
              }
            } // ? Slide about the team

            // * Log Out slide

            div.slide-confirm {
              > div i {
                // font-size: 10rem;
                // padding: 0 5rem;
                display: none;
              }
              > div div.logout-button-container {
                // max-width: none;
                width: 150%;
              }
            }
          }
        }
      }
    }
  }

  main section.waiting {
    div.slide-waiting {
      > div {
        // border: none;
        width: 100%;
        height: 100%;
        // padding: 0;
      }

      div.waiting-content {
        margin: 2rem;
      }

      div.waiting-ui,
      div.waiting-play-ui {
        margin: 0;
        width: 100%;
      }

      h4 {
        font-size: 1.85rem;
        line-height: 1.85rem;
      }

      h6 {
        font-size: 1rem;
      }
    }
  }
} // TODO max-width must be 119.9375 for anything below desktop viewport which is the container for the site. Value is for testing purposes. Adjust accordingly
