// ? Preloader Animation

@keyframes preloader-loop {
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 4rem);
  }
  100% {
    left: 0;
  }
}

// ? preloader

div.preloader {
  // background: gray none;
  position: absolute;
  top: 11rem;
  left: 0;
  z-index: -1;
  height: calc(100% - 11rem);
  color: var(--color-light-gray);
  div.preloader-holder {
    width: 25%;
    height: 15%;
    position: relative;
    // background: red none;
    // border-left: currentColor 1rem solid;
    // border-right: currentColor 1rem solid;

    div.preloader-ball {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
      top: calc(50% - 1rem);
      left: 0;
      background: currentColor none;

      animation-name: preloader-loop;
      animation-duration: 0.65s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-fill-mode: both;
    }
  }

  div.preloader-info {
    span {
      text-transform: uppercase;
      display: block;
      font-size: 1rem;
      letter-spacing: 0.188rem;
      font-weight: 700;
    }
  }
}

div.no-results {
  height: 3.063rem;
  padding: 4.188rem;
  border-bottom: var(--color-gray) 0.063rem solid;

  h4 {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

// ? Passwords inputs with show / hide button

div.password-field-container {
  input:not([hidden]) + div.password-input-toggler {
    padding: 1rem 0 0 0.5rem;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s linear;

    &:hover {
      opacity: 0.5;
    }

    i {
      color: var(--main-bg-color);
      font-size: 1.125rem;
      pointer-events: none;
    }

    &.hide {
      i:first-of-type {
        display: none;
      }
      i:last-of-type {
        display: block;
      }
    }

    &.show {
      i:first-of-type {
        display: block;
      }
      i:last-of-type {
        display: none !important;
      }
    }
  }
}

// ? Range Input

input[type='range'] {
  width: 100%;
  appearance: none;
  outline: none;
  height: 0.063rem;
  color: var(--main-bg-color);
  display: block;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 3.063rem;
  width: 3.063rem;
  border-radius: 3.063rem;
  background: var(--main-bg-color);
  cursor: pointer;
  margin-top: -1.531rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  border: none;
  height: 3.063rem;
  width: 3.063rem;
  border-radius: 3.063rem;
  background: var(--main-bg-color);
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.063rem;
  cursor: pointer;
  background: var(--color-light-gray);
  border: none;
}

input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--color-light-gray);
}

input[type='range']::-moz-range-track {
  width: 100%;
  height: 0.063rem;
  cursor: pointer;
  background: var(--color-light-gray);
  border: none;
}

// ? Button for forms

button.black-btn {
  width: 100%;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  background: var(--main-bg-color);
  color: var(--color-white);
  font-weight: 600;
  border: none;
  padding: 0.5rem 0;

  border: transparent 0.063rem solid;

  transition: background 0.25s linear, color 0.25s, border 0.25s linear;
}

button.black-btn:hover {
  color: var(--main-bg-color);
  background: transparent;
  border: var(--main-bg-color) 0.063rem solid;
}

button.white-btn {
  width: 100%;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  background: var(--color-white);
  color: var(--main-bg-color);
  font-weight: 600;
  border: none;
  padding: 0.5rem 0;

  border: transparent 0.063rem solid;

  transition: background 0.25s linear, color 0.25s, border 0.25s linear;
}

button.white-btn:hover {
  color: var(--color-white);
  background: transparent;
  border: var(--color-white) 0.063rem solid;
}

// * Responsive Styles

@media screen and (max-width: $viewport_tablet) {
  div.preloader {
    div.preloader-holder {
      width: 25%;
      height: 28%;
    }
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 2.5rem;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: vrem;
    margin-top: -1.25rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }
}

@media screen and (max-width: $viewport_phone) {
  div.preloader {
    div.preloader-holder {
      width: 50%;
      height: 25%;
    }
  }
}
