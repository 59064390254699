*,
::after,
::before {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
ol[role='list'],
ul[role='list'] {
  list-style: none;
}
html:focus-within {
  scroll-behavior: smooth;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
canvas,
img,
picture,
svg,
video {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  font-style: italic;
  background-repeat: no-repeat;
  background-size: cover;
}
button,
input,
select,
textarea {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  ::after,
  ::before {
    animation-duration: 0s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0s !important;
    scroll-behavior: auto !important;
    transition: none;
  }
}
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

#grid-system {
  width: 100%;
  height: 2000%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  pointer-events: none;
  background: url('../img/baseline.svg') top left repeat,
    url('../img/grid.svg') top center repeat-y;
}
