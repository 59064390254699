@media screen and (max-width: $viewport_phone) {
  body {
    // background: red none;
  }

  div.toaster-container {
    div.toaster-widget {
      top: 2rem;
      right: 0;
      left: 0;
      margin: 0 auto;

      width: 85%;

      background: var(--main-bg-color) none;

      transform: translateY(-12rem);

      &.show {
        transform: translateY(0);
      }
    }
  }

  main section.login {
    div.login-panels {
      max-width: none;

      div.login-splash {
        div {
          &.ball-container {
            div.ball {
              width: 30.9375rem;
              height: 30.9375rem;
              border-radius: 30.9375rem;
            }

            &.full {
              transform: scale(0.8);
            }

            &.fullest {
              transform: scale(1);
            }

            div.ball {
              width: 15.9375rem;
              height: 15.9375rem;
              background: var(--color-white);

              border-radius: 12.9375rem;
              display: block;

              transform: translateX(0);
            } // ? try to make this reposnsive
          }

          &.shovels-container {
            div.shovels {
              border-left: var(--color-white) 1rem solid;
              border-right: var(--color-white) 1rem solid;

              &.showing-shovels {
                height: 20.955532575%;
              }

              &.reducing-shovels {
                height: 20.955532575%;
              }
            }
          }

          &.letters-container {
            div.letters {
              height: 4.5rem;
              > ul {
                flex-wrap: wrap;

                li {
                  span {
                    font-size: 5.5rem;
                    line-height: 4.5rem;
                  }
                }
              }
            }
          }

          &.menu-intro-container {
            div.menu-intro {
              ul {
                text-align: center;

                flex-direction: column !important;
                li {
                  font-size: 1rem;
                  span {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }

      div.login-signin,
      div.login-signup {
        div.login-form {
          max-width: none;

          h2 {
            font-size: 2rem;
          }

          div.login-form-container {
            div.fields {
              max-width: none;
              width: 85%;
            }

            div.footer {
              position: absolute;
              bottom: 2rem;
              left: 0;
              right: 0;
              max-width: none;
              width: 85%;
              font-size: 0.875rem;

              span {
                margin: 1.5rem 0 0 0;
                text-align: center;
                display: block;
                font-weight: 600;

                a {
                  color: inherit;
                }
              }
            }
          }
        }
      }

      div.login-signup {
        div.login-form-container {
          padding: 1.25rem 0 4rem 0 !important;
        }
      }

      div.login-signin {
        div.login-form-container {
          padding: 3.58rem 0 4rem 0 !important;
        }
      }
    }
  }

  main section.app {
    div.app-section-header {
      div.avatar-container {
        div.avatar-image-container {
          width: 2.25rem;
          height: 2.25rem;
          border-radius: 2.25rem;
          margin-left: -1.25rem;
        }
      }
    }

    div.section-holder {
      div.holder-frame {
        div.holder {
          div.block-content {
            // * History Slide

            div.slide-history {
              overflow-x: hidden;

              div.history-row {
                padding: 0 2.5%;

                > div {
                  width: 33.333333333%;
                }

                div.history-avatar {
                  display: none;
                }

                div.user-data {
                  flex-direction: column !important;
                  span.history-name {
                    padding: 0;
                  }

                  span.history-score {
                    margin: 0;
                  }
                }

                div.timestamp {
                  padding: 2.97rem 0.5rem;
                  font-size: 0.813rem;
                }
                div.player-data {
                  flex-direction: column !important;
                  span.history-name {
                    padding: 0;
                  }

                  span.history-score {
                    margin: 0;
                  }
                }
              }
            } // ? History Slide

            // * Edit your info Slide

            div.slide-edit {
              // align-items: flex-start !important;
              div.edit-form-container {
                margin: 0 auto;
                height: calc(100% - 3rem);
                form {
                  margin: 3rem 0 0 0;
                  height: 100%;

                  position: relative;

                  div.edit-avatar {
                    div.avatar-image {
                      width: 3rem;
                      height: 3rem;
                      border-radius: 3rem;
                    }

                    label.avatar-file-upload {
                      font-size: 1rem;
                      letter-spacing: 0;
                      margin: 0 0 0 1.5rem;
                    }

                    span {
                      margin: 0 0 0 1.25rem;
                      font-size: 0.813rem;
                    }
                  }

                  div.edit-fields {
                    margin: 3.25rem 0 0 0;
                  }

                  div.edit-info-button-container {
                    margin: 0 auto;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 2.25rem; // ? 5 - 3.25 from edit fields
                    width: 100%;
                  }
                }
              }
            } // ? Edit your info slide

            // * Slide training

            div.slide-training {
              align-items: flex-start !important;

              div.training-form-container {
                margin: 3.75rem auto 0;
                div.training-fields {
                  > div {
                    justify-content: start !important;

                    > input {
                      flex-basis: 55%;
                      margin: 3.375rem 0 0 0;
                    }

                    > div {
                      flex-basis: 45%;
                      label {
                        text-align: left;
                      }
                    }
                  }
                }

                div.training-players-range-container {
                  margin: 5.75rem 0 0 0;

                  h4 {
                    font-size: 1rem;
                  }
                }

                div.training-button-container {
                  position: absolute;
                  bottom: 2rem;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  width: 85%;

                  &::after {
                    padding: 0;
                    top: -4rem;
                  }
                }
              }
            } // ? Slide training

            // * Slide public game
            div.slide-public {
              // > div i {
              //   display: none;
              // }

              h4 {
                font-size: 1.5rem;
              }

              > div div.public-button-container {
                // width: 120%;
                &::after {
                  padding: 0;
                  top: -4rem;
                }
              }
            } // ? Slide Public game

            // TODO this up section stills needs to be decided

            // * Slide private game

            div.slide-private {
              flex-direction: column !important;

              > div {
                width: 100% !important;
                height: 50% !important;

                &:first-of-type {
                  border-right: none;
                  border-bottom: var(--color-gray) 0.063rem solid;

                  div.private-goback-indicator {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: calc(-100% + 5rem);

                    span {
                      margin: 0;
                    }

                    i {
                      display: none;
                    }
                  }
                }

                &:last-of-type {
                  div.private-goback-indicator {
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: calc(-100% + 5rem);

                    span {
                      margin: 0;
                    }

                    i {
                      display: none;
                    }
                  }
                }

                // h4 {
                //   font-size: 1.5rem;
                // }
              }

              &.showing-create-form {
                > div:last-of-type {
                  height: 0 !important;

                  div.private-goback-indicator {
                    display: none !important;
                  }
                }
              }

              &.showing-join-form {
                > div:first-of-type {
                  height: 0 !important;

                  div.private-goback-indicator {
                    display: none !important;
                  }
                }
              }
            } // ? Slide Private game

            // TODO this up section stills needs to be decided

            // * Slide create tournament

            div.slide-create {
              align-items: flex-start !important;
              div.create-tournament-form-container {
                margin: 3.75rem auto 0 auto;

                div.create-tournament-game-points-range-container,
                div.create-tournament-player-number-range-container {
                  h4 {
                    font-size: 1rem;
                  }
                }

                div.create-tournament-button-container {
                  max-width: none;
                  width: 85%;
                  margin: 0 auto;
                  position: absolute;
                  bottom: 2rem;
                  left: 0;
                  right: 0;
                }
              }
            } // ? Slide create tournamet

            div.slide-join {
            } // ? Join game

            // TODO this up section stills needs to be decided

            // * Slide hall of fame (Blockchain)

            div.slide-hall {
              div.hall-list-container {
                div.hall-list-row-container {
                  div.hall-list-row {
                    padding: 3.188rem 0;
                    width: 100%;
                    div.left-side {
                      justify-content: space-evenly !important;
                      width: 70%;
                      div.tournament-data {
                        margin-left: 0;
                        font-size: 0.813rem;
                        div {
                          text-align: center;
                        }
                      }
                    }
                    div.right-side {
                      width: 30%;
                      // text-align: center;
                    }
                  }
                }
              }
            } // ? Slide Hall of Fame (blockchain)

            // * Slide Friends list
            div.slide-list {
              div.friends-list-container {
                div.friends-list-row-container {
                  div.friends-list-row {
                    div.friend-username {
                      font-size: 0.625rem;

                      span:first-of-type {
                        padding: 0 0 0 1.5rem;
                      }

                      &::before {
                        left: 0;
                        font-size: 1rem;
                        top: -0.45rem;
                      }

                      &:hover {
                        &::before {
                          opacity: 1;
                          transform: none, none !important;
                        }
                      }
                    }

                    div.friend-avatar {
                      margin: 0 0.5rem;
                    }

                    div[class*='friend-list-user-data'] {
                      font-size: 0.625rem;
                    }
                  }
                }
              }
            } // ? Slide friends list

            // * Slide Add friends

            div.slide-add {
              div.friends-search {
                height: 6.528rem;
                div.friends-search-ui {
                  width: 85%;
                }
              }

              div.search-list-container {
                div.search-list-row {
                  width: 85%;
                  transform: none;
                  padding: 2.188rem 0;

                  div[class*='search-list-user-data'] {
                    font-size: 0.625rem;
                  }

                  div.search-avatar {
                    margin: 0 0.5rem;
                  }
                }
              }
            } // ? Slide add friends

            // * Slide about the game

            div.slide-about-game {
              div.about-text-container {
                margin: 2.05rem auto 0 auto;
                padding: 0;
                width: 78%;
                p {
                  font-size: 1.25rem;
                  line-height: 1.875rem;
                  text-align: left;
                  text-indent: 0;
                }
              }
            } // ? Slide about game

            div.slide-about-team {
              overflow-y: auto;
              div.about-team-content {
                display: block;
                > div {
                  width: 100%;
                  height: 50%;
                  border-right: none;
                  border-left: none;
                }

                > div:first-of-type {
                  border-bottom: var(--color-gray) 0.031rem solid;
                }
              }
            }

            div.slide-confirm {
              // > div i {
              //   display: none;
              // }

              // > div div.logout-button-container {
              //   width: 150%;
              // }
            }
          }
        }
      }
    }
  }

  // main section.waiting {
  //   div.slide-waiting {
  //     > div {
  //       border: none;
  //       width: 100%;
  //       height: 100%;
  //       padding: 0;
  //     }

  //     div.waiting-content {
  //       margin: 2rem;
  //     }

  //     div.waiting-ui,
  //     div.waiting-play-ui {
  //       margin: 0;
  //       width: 100%;
  //     }

  //     h4 {
  //       font-size: 1.85rem;
  //       line-height: 1.85rem;
  //     }

  //     h6 {
  //       font-size: 1rem;
  //     }
  //   }
  // }
} // ? Under 480px
